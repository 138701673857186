<template>
  <!-- <b-card-code title="Column Search Table"> -->
    <div class="card mb-5 mb-xl-10">
      <div class="card-body">
    <!-- input search -->
    <button type="button" class="btn btn-success btn-sm mb-2 mr-2" size="sm" style="float: left; background-color:#030A8C; border-color: #030A8C" v-b-modal.modal-tambah><feather-icon
        icon="PlusCircleIcon"
        class="mr-50"
      />Tambah</button>
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="datapasar"
      :rtl="direction"
      :line-numbers="true"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item v-b-modal.modal-edit @click="getdata(props.row.id);">
                <!-- <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                /> -->
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deletedata(props.row.id)">
                <!-- <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                /> -->
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','25','50']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    <!-- modal edit -->
    <b-modal
      id="modal-edit"
      scrollable
      title="Edit Data"
      cancel-title="Close"
      ok-title="Accept"
      cancel-variant="outline-secondary"
    >
      <form
        ref="form"
        @submit.stop.prevent="ubahdata"
      >
        <b-form-group
          label="Nama Pasar"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="detaildatapasar.nama_pasar"
          />
        </b-form-group>
        <b-form-group
          label="Komoditas Utama"
          label-for="komoditasutama"
        >
          <b-form-input
            id="komoditasutama"
            v-model="detaildatapasar.komoditas_utama"
          />
        </b-form-group>
        <b-form-group
          label="Jenis Bangunan"
          label-for="jenisbangunan"
        >
          <b-form-select v-model="detaildatapasar.jenis_bangunan" :options="option_jenisbangunan"></b-form-select>
        </b-form-group>
        <b-form-group
          label="Klasifikasi"
          label-for="klasifikasi"
        >
          <b-form-select v-model="detaildatapasar.klasifikasi" :options="option_klasifikasi"></b-form-select>
        </b-form-group>
        <b-form-group
          label="Waktu Operasi"
          label-for="waktu"
        >
          <b-form-select v-model="detaildatapasar.waktu_operasi" :options="option_waktu_operasi"></b-form-select>
        </b-form-group>
        <b-form-group
          label="Tahun Mulai Operasi"
          label-for="tahunmulai"
        >
          <b-form-input
            id="name"
            type="number"
            v-model="detaildatapasar.tahun_mulai_operasi"
          />
        </b-form-group>
        <b-form-group
          label="Tahun Terakhri Renovasi"
          label-for="tahunrenovasi"
        >
          <b-form-input
            id="name"
            type="number"
            v-model="detaildatapasar.tahun_terakhir_renovasi"
          />
        </b-form-group>
        <b-form-group
          label="Perkiraan Jumlah Pedagang"
          label-for="jumlah"
        >
          <b-form-select v-model="detaildatapasar.perkiraan_jumlah_pedagang" :options="option_perkiraan_jumlah_pedagang"></b-form-select>
        </b-form-group>
        <b-form-group
          label="Info"
          label-for="info"
        >
          <b-form-input
            id="info"
            v-model="detaildatapasar.info"
          />
        </b-form-group>
        <b-form-group
          label="Provinsi"
          label-for="vue-select"
        >
          <b-form-select @input="getkabkot(detaildatapasar.kode_prov)"  v-model="detaildatapasar.kode_prov">
            <option
                v-for="(dataprovinsi, k) in dataprovinsi"
                :key="k"
                :value="dataprovinsi.kode_prov"
            >
                {{ dataprovinsi.nama }}
            </option>
          </b-form-select>
        </b-form-group>
        <b-form-group
          label="Kabupaten/Kota"
          label-for="vue-select"
        >
          <b-form-select @input="getkecamatan(detaildatapasar.kode_kab)" v-model="detaildatapasar.kode_kab">
            <option
                v-for="(datakabupaten, k) in datakabupaten"
                :key="k"
                :value="datakabupaten.kode_kab"
            >
                {{ datakabupaten.nama }}
            </option>
          </b-form-select>
        </b-form-group>
        <b-form-group
          label="Kecamatan"
          label-for="vue-select"
        >
          <b-form-select @input="getkelurahan(detaildatapasar.kode_kec)" v-model="detaildatapasar.kode_kec">
            <option
                v-for="(datakecamatan, k) in datakecamatan"
                :key="k"
                :value="datakecamatan.kode_kec"
            >
                {{ datakecamatan.nama }}
            </option>
          </b-form-select>
        </b-form-group>
        <b-form-group
          label="Keluarahan/Desa"
          label-for="vue-select"
        >
          <b-form-select v-model="detaildatapasar.kode_kel">
            <option
                v-for="(datakeluarahan, k) in datakeluarahan"
                :key="k"
                :value="datakeluarahan.kode_kel"
            >
                {{ datakeluarahan.nama }}
            </option>
          </b-form-select>
        </b-form-group>
        <b-form-group
          label="Alamat"
          label-for="alamat"
        >
          <b-form-textarea
            id="alamat"
            v-model="detaildatapasar.alamat"
            placeholder="Alamat"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>
      </form>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="float-right"
            @click="handleOk"
          >
            Kirim
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- modal tambah -->
    <b-modal
      id="modal-tambah"
      scrollable
      title="Tambah Data"
      cancel-title="Close"
      ok-title="Accept"
      cancel-variant="outline-secondary"
    >
      <form
        ref="form"
        @submit.stop.prevent="tambahdata"
      >
        <b-form-group
          label="Nama Pasar"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="detaildatapasar.nama_pasar"
          />
        </b-form-group>
        <b-form-group
          label="Komoditas Utama"
          label-for="komoditasutama"
        >
          <b-form-input
            id="komoditasutama"
            v-model="detaildatapasar.komoditas_utama"
          />
        </b-form-group>
        <b-form-group
          label="Jenis Bangunan"
          label-for="jenisbangunan"
        >
          <b-form-select v-model="detaildatapasar.jenis_bangunan" :options="option_jenisbangunan"></b-form-select>
        </b-form-group>
        <b-form-group
          label="Klasifikasi"
          label-for="klasifikasi"
        >
          <b-form-select v-model="detaildatapasar.klasifikasi" :options="option_klasifikasi"></b-form-select>
        </b-form-group>
        <b-form-group
          label="Waktu Operasi"
          label-for="waktu"
        >
          <b-form-select v-model="detaildatapasar.waktu_operasi" :options="option_waktu_operasi"></b-form-select>
        </b-form-group>
        <b-form-group
          label="Tahun Mulai Operasi"
          label-for="tahunmulai"
        >
          <b-form-input
            id="name"
            type="number"
            v-model="detaildatapasar.tahun_mulai_operasi"
          />
        </b-form-group>
        <b-form-group
          label="Tahun Terakhri Renovasi"
          label-for="tahunrenovasi"
        >
          <b-form-input
            id="name"
            type="number"
            v-model="detaildatapasar.tahun_terakhir_renovasi"
          />
        </b-form-group>
        <b-form-group
          label="Perkiraan Jumlah Pedagang"
          label-for="jumlah"
        >
          <b-form-select v-model="detaildatapasar.perkiraan_jumlah_pedagang" :options="option_perkiraan_jumlah_pedagang"></b-form-select>
        </b-form-group>
        <b-form-group
          label="Info"
          label-for="info"
        >
          <b-form-input
            id="info"
            v-model="detaildatapasar.info"
          />
        </b-form-group>
        <b-form-group
          label="Provinsi"
          label-for="vue-select"
        >
          <b-form-select @input="getkabkot(detaildatapasar.kode_prov)"  v-model="detaildatapasar.kode_prov">
            <option
                v-for="(dataprovinsi, k) in dataprovinsi"
                :key="k"
                :value="dataprovinsi.kode_prov"
            >
                {{ dataprovinsi.nama }}
            </option>
          </b-form-select>
        </b-form-group>
        <b-form-group
          label="Kabupaten/Kota"
          label-for="vue-select"
        >
          <b-form-select @input="getkecamatan(detaildatapasar.kode_kab)" v-model="detaildatapasar.kode_kab">
            <option
                v-for="(datakabupaten, k) in datakabupaten"
                :key="k"
                :value="datakabupaten.kode_kab"
            >
                {{ datakabupaten.nama }}
            </option>
          </b-form-select>
        </b-form-group>
        <b-form-group
          label="Kecamatan"
          label-for="vue-select"
        >
          <b-form-select @input="getkelurahan(detaildatapasar.kode_kec)" v-model="detaildatapasar.kode_kec">
            <option
                v-for="(datakecamatan, k) in datakecamatan"
                :key="k"
                :value="datakecamatan.kode_kec"
            >
                {{ datakecamatan.nama }}
            </option>
          </b-form-select>
        </b-form-group>
        <b-form-group
          label="Keluarahan/Desa"
          label-for="vue-select"
        >
          <b-form-select v-model="detaildatapasar.kode_kel">
            <option
                v-for="(datakeluarahan, k) in datakeluarahan"
                :key="k"
                :value="datakeluarahan.kode_kel"
            >
                {{ datakeluarahan.nama }}
            </option>
          </b-form-select>
        </b-form-group>
        <b-form-group
          label="Alamat"
          label-for="alamat"
        >
          <b-form-textarea
            id="alamat"
            v-model="detaildatapasar.alamat"
            placeholder="Alamat"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>
      </form>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="float-right"
            @click="handleOk2"
          >
            Kirim
          </b-button>
        </div>
      </template>
    </b-modal>
    
    </div>
    </div>
  <!-- </b-card-code> -->
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import Vue from 'vue';
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BFormTextarea,BFormFile,BButton,BFormDatepicker
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import { codeColumnSearch } from './code'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import axios from 'axios';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'
import VueLoading from 'vuejs-loading-plugin'

// import { VueEditor } from "vue2-editor";

// overwrite defaults
Vue.use(VueLoading, {
  dark: true, // default false
  text: 'Loading', // default 'Loading'
  loading: true, // default false
  //customLoader: myVueComponent, // replaces the spinner and text with your own
  background: 'rgb(255,255,255)', // set custom background
  classes: ['myclass'] // array, object or string
})

export default {
  components: {
    BCardCode,
    VueGoodTable,
    quillEditor,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormTextarea,
    BFormFile,
    BButton,
    BFormDatepicker,
    Ripple,
    VueLoading,
    Vue
    // VueEditor
  },
  setup(){
    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Jawaban',
    }
    return {
      editorOption
    }
  },
  data() {
    return {
      name: '',
      jawabanState: null,
      submittedNames: [],
      pageLength: 10,
      dir: false,
      codeColumnSearch,
      url: localStorage.getItem('baseapi'),
      pengguna: JSON.parse(localStorage.getItem('userData')),
      customToolbar: [
        ["bold", "italic", "underline"],
      ],
      columns: [
        {
          label: 'Nama Pasar',
          field: 'nama_pasar',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Nama Pasar',
          },
        },
        {
          label: 'Provinsi',
          field: 'provinsi',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Provinsi',
          },
        },
        {
          label: 'Pengelola',
          field: 'pengelola',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Pengelola',
          },
        },
        {
          label: 'Jumlah Pedagang',
          field: 'perkiraan_jumlah_pedagang',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Jumlah Pedagang',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
      datapasar:[],
      pertanyaan:[],
      dataprovinsi:[],
      detaildatapasar: [],
      datakabupaten: [],
      datakecamatan: [],
      datakeluarahan: [],
      option_jenisbangunan: [
        { value: 'PERMANEN', text: 'PERMANEN' },
        { value: 'TIDAK PERMANEN', text: 'TIDAK PERMANEN' },
      ],
      option_klasifikasi: [
        { value: 'PASAR TRADISIONAL', text: 'PASAR TRADISIONAL' },
        { value: 'PUSAT PERBELANJAAN', text: 'PUSAT PERBELANJAAN' },
        { value: 'TOKO SWALAYAN', text: 'TOKO SWALAYAN' },
      ],
      option_waktu_operasi: [
        { value: '1 HARI PER MINGGU', text: '1 HARI PER MINGGU' },
        { value: '2 HARI PER MINGGU', text: '2 HARI PER MINGGU' },
        { value: '3 HARI PER MINGGU', text: '3 HARI PER MINGGU' },
        { value: '4 HARI PER MINGGU', text: '4 HARI PER MINGGU' },
        { value: '5 HARI PER MINGGU', text: '5 HARI PER MINGGU' },
        { value: '6 HARI PER MINGGU', text: '6 HARI PER MINGGU' },
        { value: 'SETIAP HARI', text: 'SETIAP HARI' },
        { value: 'LAINNYA', text: 'LAINNYA' },
      ],
      option_perkiraan_jumlah_pedagang: [
        { value: '< 100 PEDAGANG', text: '< 100 PEDAGANG' },
        { value: '100 sd. 199 PEDAGANG', text: '100 sd. 199 PEDAGANG' },
        { value: '200 sd. 274 PEDAGANG', text: '200 sd. 274 PEDAGANG' },
        { value: '275 sd. 400 PEDAGANG', text: '275 sd. 400 PEDAGANG' },
        { value: '> 400 PEDAGANG', text: '> 400 PEDAGANG' },
      ],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  mounted(){
    this.loaddata()
    this.getprovinsi()
  },
  created() {
   
  },
  methods: {
    loaddata(){
      this.$loading(true);
      axios.get(this.url+'pasar', {
      headers: {
          "Content-type": "application/json",
          // "xth": this.pengguna.token,
          }
      })
      .then(response => {
        // const { users, total } = response.data.data
        this.datapasar = response.data.data
        this.$loading(false);
      })
      .catch( error => {
        this.$loading(false);
        if (error.response.data === 'Token Tidak Valid/Hak akses tidak sesuai') {
          this.$swal({
            title: error.response.data,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok',
            allowOutsideClick: false
            }).then((result) => {
            if (result.isConfirmed) {
              localStorage.removeItem('userData')
              // Redirect to login page
              this.$router.push({ name: 'auth-login' })
            }
          })
        } else {
         this.$toast({
          component: ToastificationContent,
          props: {
            title: err.response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
      })
    },
    getprovinsi(){
      axios.get(this.url+'wilayah/provinsi', {
      headers: {
          "Content-type": "application/json",
          // "xth": this.pengguna.token,
          }
      })
      .then(response => {
        this.dataprovinsi = response.data.data
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    async getkabkot(id){
      try{
      let response = await axios.get(this.url+'wilayah/kabupaten?id_prov='+id, {
      headers: {
          "Content-type": "application/json",
          // "xth": this.pengguna.token,
          }
      })
        console.log(response.data.data)
        this.datakabupaten = response.data.data
        this.getkecamatan(this.detaildatapasar.kode_kab)
      }catch(error){
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
      // .then(response => {
      //   this.datakabupaten = response.data.data
      //   console.log(this.datakabupaten)
      // })
      // .catch(() => {
      //   toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: 'Error fetching users list',
      //       icon: 'AlertTriangleIcon',
      //       variant: 'danger',
      //     },
      //   })
      // })
    },
    getkecamatan(id){
      var nama_ruang = '';
      var i = 0;
      console.log(this.datakabupaten)
      for (i in this.datakabupaten) {
            if (this.datakabupaten[i].kode_kab == id) {
                nama_ruang = this.datakabupaten[i].id;
                console.log(nama_ruang); // {a: 5, b: 6}
            }
        }
      console.log(nama_ruang)
      console.log(id)
      console.log('nama id')
      axios.get(this.url+'wilayah/kecamatan?id_kab='+nama_ruang, {
      headers: {
          "Content-type": "application/json",
          // "xth": this.pengguna.token,
          }
      })
      .then(response => {
        this.datakecamatan = response.data.data
        this.getkelurahan(this.detaildatapasar.kode_kec)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    getkelurahan(id){
      var nama_ruang = '';
      var i = 0;
      console.log(this.datakecamatan)
      for (i in this.datakecamatan) {
            if (this.datakecamatan[i].kode_kec == id) {
                nama_ruang = this.datakecamatan[i].id;
                console.log(nama_ruang); // {a: 5, b: 6}
            }
        }
      console.log(nama_ruang)
      console.log(id)
      console.log('nama id')
      axios.get(this.url+'wilayah/desa?id_kec='+nama_ruang, {
      headers: {
          "Content-type": "application/json",
          // "xth": this.pengguna.token,
          }
      })
      .then(response => {
        this.datakeluarahan = response.data.data
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    getdata(id){
      axios.get(this.url+'pasar/'+id, {
      headers: {
          "Content-type": "application/json",
          // "xth": this.pengguna.token,
          }
      })
      .then(response => {
        console.log('jawaban')
        console.log(response.data.data)
        this.detaildatapasar = response.data.data
        // const { users, total } = response.data.data
        // this.pengaduan = response.data.data
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    deletedata(id){
      axios
            .delete(
            this.url+`pasar/${id}`,
            {
                headers: {
                    "Content-type": "application/json",
                    // "xth": this.token,
                }
            }
            )
            .then((res) => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Success`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: `Berhasil`,
                    },
                  })
                this.loaddata()
            return res;
            })
            .catch((err) => {
            console.log(err)
            return err;
        });
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.ubahdata()
    },
    handleOk2(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.tambahdata()
    },
    ubahdata() {
      axios
            .put(
            this.url+`pasar/`+this.detaildatapasar.id,
            {
                // idptsp,
                nama_pasar:this.detaildatapasar.nama_pasar,
                data_pasar:this.detaildatapasar.data_pasar,
                kode_prov:this.detaildatapasar.kode_prov,
                kode_kab:this.detaildatapasar.kode_kab,
                kode_kec:this.detaildatapasar.kode_kec,
                kode_kel:this.detaildatapasar.kode_kel,
                alamat:this.detaildatapasar.alamat,
                klasifikasi:this.detaildatapasar.klasifikasi,
                komoditas_utama:this.detaildatapasar.komoditas_utama,
                waktu_operasi:this.detaildatapasar.waktu_operasi,
                tahun_mulai_operasi:this.detaildatapasar.tahun_mulai_operasi,
                tahun_terakhir_renovasi:this.detaildatapasar.tahun_terakhir_renovasi,
                pengelola:this.detaildatapasar.pengelola,
                perkiraan_jumlah_pedagang:this.detaildatapasar.perkiraan_jumlah_pedagang,
                jenis_bangunan:this.detaildatapasar.jenis_bangunan,
                info:this.detaildatapasar.info,
            }
            ,{
                headers:{
                // "xth": this.token
                }
            })
            .then(response => {
            
              this.loaddata()
              this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Success`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: `Berhasil`,
                    },
                  })
            
              console.log(response.data)
              
              return response
              
            })
            .catch(error=>{
            console.log(error)
            
              return error
          })

      // Hide the modal manually
      this.$nextTick(() => {
          this.$bvModal.hide('modal-edit')
      })
    },
    tambahdata(){
      const  nama_pasar=this.detaildatapasar.nama_pasar;
      const  data_pasar = this.detaildatapasar.data_pasar;
      const  kode_prov=this.detaildatapasar.kode_prov;
      const  kode_kab=this.detaildatapasar.kode_kab;
      const  kode_kec=this.detaildatapasar.kode_kec;
      const  kode_kel=this.detaildatapasar.kode_kel;
      const  alamat=this.detaildatapasar.alamat;
      const  klasifikasi=this.detaildatapasar.klasifikasi;
      const  komoditas_utama=this.detaildatapasar.komoditas_utama;
      const  waktu_operasi=this.detaildatapasar.waktu_operasi;
      const  tahun_mulai_operasi=this.detaildatapasar.tahun_mulai_operasi;
      const  tahun_terakhir_renovasi=this.detaildatapasar.tahun_terakhir_renovasi;
      const  pengelola=this.detaildatapasar.pengelola;
      const  perkiraan_jumlah_pedagang=this.detaildatapasar.perkiraan_jumlah_pedagang;
      const  jenis_bangunan=this.detaildatapasar.jenis_bangunan;
      const  info=this.detaildatapasar.info;
        axios
            .post(
            this.url+"pasar",
            {
                nama_pasar,
                data_pasar,
                kode_prov,
                kode_kab,
                kode_kec,
                kode_kel,
                alamat,
                klasifikasi,
                komoditas_utama,
                waktu_operasi,
                tahun_mulai_operasi,
                tahun_terakhir_renovasi,
                pengelola,
                perkiraan_jumlah_pedagang,
                jenis_bangunan,
                info
            },
            {
            headers: {
                "Content-type": "application/json",
                // "xth": this.token,
                }
            }
            )
            .then((res) => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Success`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: `Berhasil`,
                    },
                  })
                this.loaddata()
                // localStorage.setItem('idprofil', JSON.stringify(res.data.id))
            return res;
            })
            .catch((err) => {
            this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: err.response.data.message,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                    },
                  })
            return err;
        });
        
        this.$nextTick(() => {
          this.$bvModal.hide('modal-tambah')
        })
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
<style lang="scss" scoped>
form ::v-deep {
  // Quill Editor Style
  .quill-editor {
    .ql-container.ql-snow {
      border-bottom: 0 !important;
    }
  }
}
</style>